<!--START TESTIMONIES-->
<section class='section' id='testimonies'>
  <div class='container'>
    <div class='row'>
      <div class='col-lg-8 offset-lg-2'>
        <h1 class='section-title text-center'>What they've said</h1>
        <div class='section-title-border margin-t-20'></div>
        <p class='section-subtitle text-muted text-center font-secondary padding-t-30'>We value your option and
          seek to provide the very best experience for our customers. Please add your comments and let us know how
          we are doing.<br />
          <a href='https://www.google.com/search?q=sullivan+excavating&rlz=1C1CHBF_enUS872US872&oq=Sullivan+excavating&aqs=chrome.0.0i355i457j46i175i199l2j0j0i22i30j69i61j69i60l2.12734j0j7&sourceid=chrome&ie=UTF-8#lrd=0x53620be318b78657:0xe89e42a09340867,3,,,'
            class='btn btn-custom margin-t-30'>Write a review</a>
        </p>
      </div>
    </div>

    <div class='row margin-t-50'>

      <div class='col-lg-4'>
        <div class='testimonial-box margin-t-30'>
          <div class='testimonial-decs p-4'>
            <div class='testi-icon'>
              <i class='mdi mdi-format-quote-open display-2'></i>
            </div>
            <div class='avatar'>
              <ngx-avatar name='Pat Lowery' [style]='customStyle'></ngx-avatar>
            </div>
            <div class='p-1'>
              <h5 class='text-center text-uppercase mb-3'>Pat Lowery - <span
                  class='text-muted text-capitalize'>Deer Lake</span></h5>
              <p class='text-muted text-center mb-0'>“We have used Sullivan Excavating for several jobs ranging
                from building site preparation to road construction. Each time the work has met or exceeded our
                expectation, was completed within the identified time constraints and as well as the cost
                estimates initially provided. We will continue to use their services and won’t hesitate to
                recommend them when asked!”</p>
            </div>
          </div>
        </div>
      </div>

      <div class='col-lg-4'>
        <div class='testimonial-box margin-t-30'>
          <div class='testimonial-decs p-4'>
            <div class='testi-icon'>
              <i class='mdi mdi-format-quote-open display-2'></i>
            </div>
            <div class='avatar'>
              <ngx-avatar name='Donna Busch' [style]='customStyle'></ngx-avatar>
            </div>
            <div class='p-1'>
              <h5 class='text-center text-uppercase mb-3'>Donna Busch - <span
                  class='text-muted text-capitalize'>Loon Lake</span></h5>
              <p class='text-muted text-center mb-0'>“Our yard looks amazing. Even our neighbors are excited.
                He treated us how he would want someone to treat his own family. He's amazing. Thanks”</p>
            </div>
          </div>

        </div>
      </div>

      <div class='col-lg-4'>
        <div class='testimonial-box margin-t-30'>
          <div class='testimonial-decs p-4'>
            <div class='testi-icon'>
              <i class='mdi mdi-format-quote-open display-2'></i>
            </div>
            <div class='avatar'>
              <ngx-avatar name='Robyn Deaton' [style]='customStyle'></ngx-avatar>
            </div>
            <div class='p-1'>
              <h5 class='text-center text-uppercase mb-3'>Robyn Deaton - <span
                  class='text-muted text-capitalize'>Seattle</span></h5>
              <p class='text-muted text-center mb-0'>“Erin Sullivan is a life saver. When I called him about
                an emergency sewer leak he came right over and did a fantastic job of repairing it. So when
                I had an emergency water leak I called him and once again he came right out and explained
                what I needed to do and was able to fix it the next day. I highly recommend him!”</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!--END TESTIMONIES-->