<!--START PROJECTS-->
<section class='section' id='projects'>
  <div class='container'>
    <div class='row'>
      <div class='col-lg-10 offset-lg-1'>
        <div class='container-fluid'>
          <h1 class='section-title text-center'>Recent Projects</h1>
          <div class='section-title-border margin-t-20'></div>
          <br />

          <carousel [images]='images' [height]='400' [cellWidth]='400'> </carousel>

        </div>
      </div>
    </div>
  </div>
</section>
<!--END PROJECTS-->