<div class='home-registration-form bg-gray'>
  <h4 class='form-heading text-center mt-2'>What can we do for you</h4>
  <form [formGroup]='emailForm' (ngSubmit)='emailButton.onSubmit()'>

    <div class='validation-container'>
      <input type='text' class='form-control registration-input-box' placeholder='Your name*' formControlName='name'>
      <span class='validation-warning' *ngIf='name.errors && (name.dirty || name.touched)'>
        <span *ngIf='name.errors.required'> Name required </span>
        <span *ngIf='name.errors.minlength'> Must be greater than 2 characters </span>
      </span>
    </div>

    <div class='validation-container'>
      <input type='email' class='form-control registration-input-box' placeholder='Your email*' formControlName='email'>
      <span class='validation-warning' *ngIf='email.errors && (email.dirty || email.touched)'>
        <span *ngIf='email.errors.required'> Email required </span>
        <span *ngIf='email.errors.email'> Must be valid email format </span>
      </span>
    </div>

    <input type='text' class='form-control registration-input-box address-style' placeholder='Your address (optional)'
      formControlName='address' autocomplete='off'>

    <textarea class='form-control registration-textarea-box' rows='4' placeholder='Your message (optional)'
      formControlName='message'></textarea>

    <app-email-button [emailForm]='emailForm' #emailButton></app-email-button>

  </form>
</div>