<!--START ABOUT-->
<section class='section' id='about'>

  <div class='container'>

    <div class='row'>
      <div class='col-lg-8 offset-lg-2 text-center'>
        <img src='assets/images/logo-ver-2.svg' style='width:198px; height:86px; opacity: 0.8;' alt='logo'>
        <div class='section-title-border margin-t-20'></div>
      </div>
    </div>

    <div class='row align-items-center justify-content-center'>
      <div class='col-lg-9 order-lg-1'>
        <p class='section-subtitle text-muted text-center padding-t-30 font-secondary'>The Sullivan Family
          Construction Business was established in Seattle, Washington over 35 years ago. We are now pleased to be
          in Steven's Country to serve you. Our motto has always been 'PRIDE COMES FROM A JOB WELL DONE'.</p>
      </div>
    </div>

    <div class='row align-items-center justify-content-center'>
      <div class='col-lg-9 order-lg-1'>
        <div class='features-box mt-7 mt-lg-0'>
          <br />
          <h4>Why choose Sullivan Excavating</h4>
          <ul class='text-muted list-unstyled margin-t-30 features-item-list'>
            <li>We give free estimates</li>
            <li>We comply to the highest safety standards before, during and after the job</li>
            <li>We are commited to excellence, outstanding service, quality workmanship and competitive pricing</li>
            <li>We serve both residential and commercial customers</li>
            <li>We listen to your specification to ensure that your special needs are met</li>
            <li>We firmly believe in supporting our community by contributing to causes that make a difference in the
              lives of our neighbors, families and customers</li>
            <li>We guarantee your satification</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</section>
<!--END ABOUT-->