<!--START SERVICES-->
<section class='section bg-light' id='services'>
  <div class='container'>
    <div class='row'>
      <div class='col-lg-8 offset-lg-2'>
        <h1 class='section-title text-center'>Our Services</h1>
        <div class='section-title-border margin-t-20'></div>
        <p class='section-subtitle text-muted text-center padding-t-30 font-secondary'>We offer a wide variety of
          services to suit your individual and business needs. Whether it's a trench, a foundation or straightening up
          your driveway, we can do it!</p>
      </div>
    </div>
    <div class='row margin-t-30'>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-diamond text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Site Preparation</h4>
              <p class='pt-2 text-muted'>Prepare a level building site for construction including road
                building, land clearing, demolition of existing structures/obstacles, earthmoving and/or drainage.</p>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-display2 text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Foundation Excavation</h4>
              <p class='pt-2 text-muted'>Dig an area suitable for basements, crawlspaces and/or foundations in
                preparation for laying concrete forms.</p>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-piggy text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Demolition</h4>
              <p class='pt-2 text-muted'>Knock down single family dwellings, multi-family housing and/or commercial
                buildings. Remove demolition material and backfill area to provide a clean level space.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-science text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Utility Trenching</h4>
              <p class='pt-2 text-muted'>Notifiy utilities and obtain necessary permits before trenching for water
                lines, sewer lines, hookups, water drainage pipes and/or catch basins.</p>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-news-paper text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Land Clearing</h4>
              <p class='pt-2 text-muted'>Provide qualified, experienced and affordable tree and stump removal.</p>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-plane text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Finish Grading</h4>
              <p class='pt-2 text-muted'>Dress up your site around new and existing homes to provide that professional
                appearance. Apply top soil based upon customer requirements.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-car text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Driveways</h4>
              <p class='pt-2 text-muted'>Build roads and driveways in difficult site and soil conditions ready for
                vehicular traffic. Overlay and pack with gravel as necessary.</p>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 margin-t-20'>
        <div class='services-box'>
          <div class='media'>
            <i class='pe-7s-timer text-custom'></i>
            <div class='media-body ml-4'>
              <h4>Erosion Control</h4>
              <p class='pt-2 text-muted'>Apply silt fence installation, french drains and positive drainage systems.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--END SERVICES-->