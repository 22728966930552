<section class='section' id='footer'>
  <div class='container'>
    <div class='row'>
      <div class='col-lg-8 offset-lg-2'>
        <h5 class='text-center text-muted'>Designed by: Water Street Works</h5>
      </div>
    </div>
    <div class='row'>
      <div class='col-lg-8 offset-lg-2'>
        <h5 class='text-center text-muted'>Hosted by: Google Firebase</h5>
      </div>
    </div>
    <div class='row'>
      <div class='col-lg-8 offset-lg-2'>
        <img class='img-fluid rounded mx-auto d-block' src='assets/images/firebase.png' style='width:116px;height:32px;' alt='firebase image'>
      </div>
    </div>
  </div>
</section>