<!--START NAVBAR-->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">

    <!--START LOGO-->
    <div class="navbar-brand logo text-uppercase">
      Sullivan
    </div>
    <!--END LOGO-->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a href="javascript: void(0);" [ngx-scroll-to]="'home'" class="nav-link">Home</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'about'}">
          <a href="javascript: void(0);" [ngx-scroll-to]="'about'" class="nav-link">About</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a href="javascript: void(0);" [ngx-scroll-to]="'services'" class="nav-link">Services</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'testimonies'}">
          <a href="javascript: void(0);" [ngx-scroll-to]="'testimonies'" class="nav-link">Testimonies</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
          <a href="javascript: void(0);" [ngx-scroll-to]="'contact'" class="nav-link">Contact</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'projects'}">
          <a href="javascript: void(0);" [ngx-scroll-to]="'projects'" class="nav-link">Projects</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--END NAVBAR-->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!--START HOME-->
  <section class="section bg-home home-half home-registration" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 text-white text-left">
          <h4 class="home-small-title">Sullivan Excavating</h4>
          <h1 class="home-title">Serving Stevens and Spokane Counties</h1>
          <p class="padding-t-15 home-desc">Please click below to see our services or send
            us an email for either answers to your questions or to receive a quote.</p>
          <a href="javascript: void(0);" [ngx-scroll-to]="'services'" class="btn btn-custom margin-t-30">Services</a>
        </div>
        <div class="col-lg-4 offset-lg-1 mt-5 mt-lg-0">
          <app-email-form></app-email-form>
        </div>
      </div>
    </div>
  </section>
  <!--END HOME-->

  <app-about></app-about>
  <app-services></app-services>
  <app-testimonies></app-testimonies>
  <app-contact></app-contact>
  <app-projects></app-projects>
  <app-footer></app-footer>

</div>